import './footer.css';

function Footer(){

    return(
      <div className={'footer'}>
          <h2 className="footertext">Made with 핯 and S볇ar Dust by DaeheeCodes 슿</h2>
      </div>
    );
  }
  export default Footer;